import React, { useEffect } from 'react';
import { FORM_COMPANY_SALES } from '../../constants/form/form';
import { COLUMN } from './constants/column';
import TabLayout from '../../components/tab-layout';
import ManageModal from '../../components/manage-modal';
import useCompanySalesState from '../../hooks/use-company-sales-state';
import { mapSaleName } from '../../../telesales/logic';
import { ButtonTheme } from '../../../../components/buttons';
import CompanyAssignModal from '../../components/company-assign-modal';
import { useRemoveCompanyTeleSale, useUpdateAdminSale } from '../../hooks/use-comapny-sales-query';
import { useSelector } from 'react-redux';
import { openAlert } from '../../../../components/alert/useAlert';
import { Icon } from '../../../../resources';
import { Checkbox, Col, Row } from 'antd';

function CompanySales() {
  const { user } = useSelector((state) => state.authen);
  const [selectedAll, setSelectedAll] = React.useState(false);

  const {
    tableProps,
    selectProps,
    paginationProps,
    options,
    onSave,
    onSearch,
    onClearCustom,
    dataTable,
  } = useCompanySalesState();
  const [modalProps, setModalProps] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [openAssignModal, setAssignModal] = React.useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);

  const updateCompanySale = useUpdateAdminSale();
  const removeCompanyTelesale = useRemoveCompanyTeleSale();
  const isSuperAdmin = user?.role === 'SUPER_ADMIN';

  useEffect(() => {
    if (dataTable) {
      onChangePageSelectAll();
    }
  }, [paginationProps.pageStep, dataTable]);

  const onToggleRow = (details) => {
    setModalProps({
      title: `จัดการสิทธิ์ ${details?.name}`,
      defaultValues: details?.code ? details?.code?.map((v) => ({ id: v, value: v })) : [],
      id: details?.id,
    });
    setOpen(!open);
  };

  const onOpenModal = () => {
    setAssignModal(true);
  };

  const onCloseModal = () => {
    setAssignModal(false);
  };

  const onSuccessAssign = () => {
    onCloseModal();
    onClearSelected();
  };

  const onClearSelected = () => {
    setSelectedRowKeys([]);
    setSelectedRows([]);
    setSelectedAll(false);
  };

  const onSaveAssignMultiple = async (body) => {
    await updateCompanySale.mutateAsync(body);
  };

  const removeCompanyOwner = async (row) => {
    openAlert({
      model: 'confirm',
      title: 'ต้องการนำบริษัทที่คุณดูแลอยู่ออก ใช่หรือไม่',
      okText: 'นำออก',
      cancelText: 'ยกเลิก',
      onOk: async () => {
        const body = {
          id: row.id,
        };
        await removeCompanyTelesale.mutateAsync(body);
      },
    });
  };

  const onChangePageSelectAll = () => {
    if (dataTable.length > 0) {
      const isAllSelected = dataTable.every((key) => selectedRowKeys.includes(key.id));
      setSelectedAll(isAllSelected);
      return;
    }
    setSelectedAll(false);
  };

  const onSelectAll = (e) => {
    setSelectedAll(e.target.checked);
    const filterId = tableProps.dataSource.map((v) => v.id);
    let newSelectedKey = [];
    let newSelectedRow = [];
    if (e.target.checked) {
      newSelectedKey = [...new Set(filterId.concat(selectedRowKeys))];
      newSelectedRow = tableProps.dataSource.filter((el, index) => {
        const value = JSON.stringify(el);
        return (
          index ===
          tableProps.dataSource.findIndex((obj) => {
            return JSON.stringify(obj) === value;
          })
        );
      });
    } else {
      newSelectedRow = selectedRows.filter((el) => {
        if (!filterId.includes(el.id)) {
          newSelectedKey.push(el.id);
          return el;
        }
      });
    }
    setSelectedRowKeys(newSelectedKey);
    setSelectedRows(newSelectedRow);
  };

  const rowSelection = {
    columnTitle: <Checkbox checked={selectedAll} onChange={onSelectAll} />,
    selectedRowKeys,
    onSelect: (row) => {
      let arrKey = [];
      let arrRow = [];
      if (selectedRowKeys.includes(row.id)) {
        //remove
        arrRow = selectedRows.filter((item) => {
          if (item.id !== row.id) {
            arrKey.push(item.id);
            return item;
          }
        });
      } else {
        //add
        arrKey = selectedRowKeys.concat([row.id]);
        arrRow = selectedRows.concat(row);
      }
      setSelectedRowKeys(arrKey);
      setSelectedRows(arrRow);
      const isAllSelected = tableProps.dataSource.every((key) => arrKey.includes(key.id));
      setSelectedAll(isAllSelected);
    },
  };

  return (
    <>
      <TabLayout
        searchLayoutProps={{
          title: 'ตัวกรอง',
          isEnableEnterSubmit: true,
          formSearch: FORM_COMPANY_SALES({ options }),
          onSearch: onSearch,
          onClearCustom: onClearCustom,
        }}
        tableProps={{
          preserveSelectedRowKeys: true,
          rowKey: (record) => record.id,
          rowSelection: {
            hideSelectAll: true,
            type: 'checkbox',
            ...rowSelection,
          },
          size: 'small',
          columns: COLUMN({
            pageStep: paginationProps?.pageStep,
            onToggleRow,
            saleCodeOptions: options?.saleCode,
            role: user?.role,
            removeCompanyOwner,
          }),
          scroll: { x: 'max-content' },
          style: { width: '100%' },
          contentsExtra: isSuperAdmin && (
            <Row gutter={[16, 16]} style={{ width: '100%' }}>
              <Col span={12} style={{ alignContent: 'center' }}>
                จำนวนบริษัทที่เลือก: {selectedRows.length || 0}
              </Col>
              <Col span={12} style={{ width: '100%', justifyContent: 'right', display: 'flex' }}>
                {selectedRowKeys.length > 0 && (
                  <ButtonTheme
                    useFor="CUSTOM"
                    title="เคลียร์บริษัทที่เลือก"
                    icon={<Icon.clear />}
                    type="default"
                    onClick={onClearSelected}
                  />
                )}
                <ButtonTheme
                  useFor="CUSTOM"
                  title="Multiple Assign"
                  icon={<Icon.userOutline />}
                  type="primary"
                  disabled={selectedRowKeys.length === 0}
                  onClick={() => onOpenModal()}
                />
              </Col>
            </Row>
          ),
          ...tableProps,
        }}
        paginationProps={paginationProps}
      />
      <ManageModal
        open={open}
        onToggle={() => setOpen((prevOpen) => !prevOpen)}
        {...modalProps}
        onSave={onSave}
        selectProps={{
          selectValueKey: 'id',
          placeholder: 'เลือกรหัสเซล',
          mappingData: (values) => ({
            id: values.id,
            value: values.value,
            label: values.label,
          }),
          ...selectProps,
        }}
        renderRow={(item) => <span>{mapSaleName(item.value, options?.saleCode)}</span>}
      />
      {openAssignModal && (
        <CompanyAssignModal
          open={openAssignModal}
          options={options}
          onClose={onCloseModal}
          onSuccess={onSuccessAssign}
          onSave={onSaveAssignMultiple}
          selectedCompanyId={selectedRowKeys}
          selectedCompanyRows={selectedRows}
          selectProps={{
            selectValueKey: 'id',
            placeholder: 'เลือกรหัสเซล',
            mappingData: (values) => ({
              id: values.id,
              value: values.value,
              label: values.label,
            }),
            ...selectProps,
          }}
        />
      )}
    </>
  );
}

export default CompanySales;
