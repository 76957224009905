export function convertBase64toBinaryString(data) {
  if (!data) return null;
  return Uint8Array.from(window.atob(data), (char) => char.charCodeAt(0));
}

export async function downloadCSV(data, fileName = `${new Date().toISOString().split('T')[0]}`) {
  return new Promise(function (resolve, reject) {
    try {
      const binaryString = data;
      const blob = new Blob([binaryString], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
      URL.revokeObjectURL(url);
      return resolve();
    } catch (error) {
      return reject();
    }
  });
}
