import { GET, GET_WITH_PARAMS, POST, POST_CUSTOM } from '../../../services';
import { env } from '../../../utils/env/config';

export const getList = async (params) => {
  try {
    const response = await GET_WITH_PARAMS(`/tls-company/list`, params);
    return response.result;
  } catch (err) {
    throw new Error('error');
  }
};

export const editDetails = async ({ companyId, reqBody }) => {
  try {
    await POST(`/tls-company/update-company/${companyId}`, reqBody);
  } catch (err) {
    throw new Error('error');
  }
};

export const packageDetails = async (companyId) => {
  try {
    const response = await GET(`/tls-company/package/${companyId}`);
    return response;
  } catch (err) {
    throw new Error('error');
  }
};

export const applyCountDetail = async (companyId) => {
  try {
    const response = await GET(`/tls-company/apply-count/${companyId}`);
    return response;
  } catch (err) {
    throw new Error('error');
  }
};

export const resetPassword = async (body) => {
  try {
    const response = await POST_CUSTOM(
      env.company_web,
      `/app/authen/user_company_forgot_password`,
      body,
    );
    return response;
  } catch (err) {
    throw new Error('error');
  }
};

export const exportToExcel = async (reqBody, config) => {
  const response = await GET_WITH_PARAMS(`/tls-company/export`, reqBody, {}, config);
  return response;
};
