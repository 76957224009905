import { useState } from 'react';
import { useCompanySaleTableQuery, useUpdateAdminSale } from './use-comapny-sales-query';
import {
  useCompanyOptionQuery,
  useheadCountRangeOptionQuery,
  useSalesCodeOptionQuery,
} from './use-options-query';
import { omitBy } from 'lodash';
import { openAlert } from '../../../components/alert/useAlert';
import { numberComma } from '../../../utils/number';

const useCompanySalesState = () => {
  const [filters, setFilters] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const companySaleList = useCompanySaleTableQuery(filters);
  const updateCompanySale = useUpdateAdminSale();
  const saleCodeOptions = useSalesCodeOptionQuery();
  const headcountRange = useheadCountRangeOptionQuery();
  const companyNameOptions = useCompanyOptionQuery();

  const onSave = async ({ id, updateValues }) => {
    try {
      const body = {
        companyIds: [id],
        code: updateValues.filter((u) => u.status === 'added' || !u.status).map((u) => u.id),
      };
      await updateCompanySale.mutateAsync(body);
    } catch (e) {
      openAlert({
        type: 'error',
        message: 'เกิดข้อผิดพลาด',
      });
    }
  };

  const onSearch = (newValues) => {
    const updatedFilter = {
      pageIndex: 0,
      pageSize: 10,
      ...newValues,
    };

    const filteredUpdatedFilter = omitBy(updatedFilter, (v) => v == null);
    setFilters(filteredUpdatedFilter);
  };

  const onClearCustom = () => {
    const updatedFilter = { pageIndex: 0, pageSize: 10 };
    setFilters(updatedFilter);
  };

  const onPageChange = (currentPage, currentPageSize) => {
    setFilters((prev) => ({
      ...prev,
      pageIndex: currentPage - 1,
      pageSize: currentPageSize,
    }));
  };

  const selectProps = {
    loading: saleCodeOptions?.loading || false,
    options: (disabledValues) => {
      const formattedOpt = saleCodeOptions?.data;

      if (!disabledValues) return formattedOpt;
      return formattedOpt.filter((f) => !disabledValues.some((d) => d.id === f.id));
    },
  };

  const tableProps = {
    loading: companySaleList?.isLoading || false,
    dataSource: companySaleList?.data?.data || [],
  };

  const paginationProps = {
    current: filters.pageIndex + 1,
    pageSize: filters.pageSize,
    total: companySaleList?.data?.total || 0,
    showTotal: (total) => `ทั้งหมด ${numberComma(total)}`,
    pageStep: filters.pageIndex * filters.pageSize + 1,
    onChange: onPageChange,
  };

  return {
    options: {
      saleCode: saleCodeOptions?.data || [],
      headcountRange: headcountRange?.data || [],
      companyNameOptions: companyNameOptions?.data || [],
    },
    selectProps,
    tableProps,
    paginationProps,
    onSave,
    onSearch,
    onClearCustom,
    dataTable: companySaleList?.data?.data,
  };
};

export default useCompanySalesState;
