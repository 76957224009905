import React from 'react';
import { Col, Divider, Row, Space, Tabs } from 'antd';
import EditForm from './views/edit-form';
import { color } from '../../../../resources';
import { ModalTheme } from '../../../../components/modal';
import { ButtonTheme } from '../../../../components/buttons';
import Information from '../../components/information';
import { useFormState } from './views/edit-form/hooks/useFormState';
import { useOptionsContext } from '../../contexts/options-context';
import { displayTabs } from '../call-modal/constants';

function EditCallInformationModal(props) {
  const { companyDetails, ...modalProps } = props;
  const { options } = useOptionsContext();

  const { formProps, onSubmit, onCancel } = useFormState({
    companyDetails,
    onCloseModal: props.onToggle,
    options,
  });

  const onCloseModal = () => {
    onCancel({
      onAfterFinish: props.onToggle,
    });
  };

  return (
    <ModalTheme
      title="แก้ไขข้อมูล"
      width={'100vw'}
      style={{ height: '100vh' }}
      {...modalProps}
      onToggle={onCloseModal}
      footer={<Footer onSubmit={onSubmit} onCancel={onCloseModal} />}
    >
      <Row gutter={8}>
        <Col span={12} style={{ paddingRight: 20 }}>
          <Information {...companyDetails} />
          <Divider />
          <EditForm {...formProps} />
        </Col>
        <Col span={12}>
          <Tabs
            type="card"
            defaultActiveKey="1"
            size={'small'}
            style={{
              marginBottom: 32,
            }}
            items={displayTabs({ companyId: companyDetails?.company?._id, companyDetails }).map(
              (e) => e,
            )}
          />
        </Col>
      </Row>
    </ModalTheme>
  );
}

function Footer({ onSubmit, onCancel }) {
  return (
    <Space>
      <ButtonTheme
        useFor="CUSTOM"
        title="ยกเลิก"
        type="primary"
        color={color.grey400}
        onClick={onCancel}
      />
      <ButtonTheme
        useFor="CUSTOM"
        title="บันทึก"
        type="primary"
        color={color.blue80}
        onClick={onSubmit}
      />
    </Space>
  );
}

export default EditCallInformationModal;
