import React, { useState } from 'react';
import { column } from './constants/column';
import TableCustom from '../../components/table-custom';
import { useModalContext } from '../../contexts/modal-context';
import { useSalesCodeOptionQuery } from '../../../assign-telesales/hooks/use-options-query';
import ButtonExcelEmbedded from '../../../../components/report-excel/ButtonExcelEmbedded';
import { downloadCSV } from '../../../../functions/download-file';
import { useSelector } from 'react-redux';
import { TextDownloadCSVHint } from './styled-components';
import * as tableApi from '../../services';
import { delay } from '../../../../functions';

import _ from 'lodash';
import { Progress } from 'antd';
import { openAlert } from '../../../../components/alert/useAlert';

const tableProps = {
  scroll: { x: 'max-content' },
  style: {
    margin: 'unset',
    padding: '8px',
    backgroundColor: 'white',
  },
};

function TelesalesTable({ dataSource, options, pageStep, isLoading, searchFilters }) {
  const { openModal } = useModalContext();
  const salesCodeOption = useSalesCodeOptionQuery();
  const { user } = useSelector((state) => state.authen);
  const isSuperAdmin = user?.role === 'SUPER_ADMIN';
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [isLoadingExportExcel, setIsLoadingExportExcel] = useState(false);

  const onToggleContact = (companyDetails) => {
    openModal('CONTACT_MODAL', { companyDetails: companyDetails });
  };

  const onToggleHistoryCall = (companyId) => {
    openModal('HISTORY_CALL_MODAL', { companyId: companyId });
  };

  const onToggleEditCallInfo = (companyDetails) => {
    openModal('EDIT_CALL_INFOMATION_MODAL', { companyDetails: companyDetails });
  };

  const onTogglePackageModal = (companyDetails) => {
    openModal('PACKAGE_ITEMS_MODAL', { companyDetails: companyDetails });
  };

  const onToggleApplicantModal = (companyDetails) => {
    openModal('APPLICANTS_COUNT_MODAL', { companyDetails: companyDetails });
  };

  const onToggleNoteModal = (companyDetails) => {
    openModal('NOTE_MODAL', { companyDetails: companyDetails });
  };

  const onToggleAdditionalNoteModal = (companyDetails) => {
    openModal('NOTE_MODAL', { companyDetails: companyDetails, title: 'โน้ตข้อความเพิ่มเติม' });
  };

  const onToggleQuotationModal = (companyDetails, quotations) => {
    openModal('QUOTATION_MODAL', { companyDetails: companyDetails, quotations: quotations });
  };

  const onExport = async (columns) => {
    try {
      setIsLoadingExportExcel(true);
      const cloneSearchFilter = _.cloneDeep(searchFilters);
      delete cloneSearchFilter.pageIndex;
      delete cloneSearchFilter.pageSize;
      const body = {
        fields: columns,
        ...cloneSearchFilter,
      };
      const config = {
        responseType: 'stream',
        onDownloadProgress: function (progressEvent) {
          const totalLength = progressEvent.total || 1000000;
          if (totalLength > 0) {
            const progress = Math.round((progressEvent.loaded / totalLength) * 100);
            if (progress <= 100) {
              setDownloadProgress(progress);
            }
          }
        },
      };
      const res = await tableApi.exportToExcel(body, config);
      downloadCSV(res);
      setDownloadProgress(100);
    } catch (err) {
      openAlert({
        type: 'error',
        message: 'เกิดข้อผิดพลาด ระบบ export ไม่สำเร็จ',
      });
    } finally {
      await delay(3000);
      setDownloadProgress(0);
      setIsLoadingExportExcel(false);
    }
  };

  return (
    <div>
      {isSuperAdmin && (
        <div style={{ justifySelf: 'right', display: 'grid' }}>
          {isLoadingExportExcel && <Progress percent={downloadProgress} size="small" />}
          <ButtonExcelEmbedded
            loading={isLoadingExportExcel}
            title="Export แบบเลือกคอลัมน์"
            canChooseColumn={true}
            onCustomExport={onExport}
            header={column({
              onToggleContact,
              onToggleHistoryCall,
              onToggleEditCallInfo,
              onTogglePackageModal,
              onToggleApplicantModal,
              onToggleNoteModal,
              onToggleQuotationModal,
              onToggleAdditionalNoteModal,
              options,
              salesCodeOption,
              pageStep,
            })}
            data={dataSource}
          />
          <TextDownloadCSVHint>
            *เมาส์ชี้ที่ปุ่มเพื่อเลือกคอลัมน์ และกดปุ่มเพื่อโหลด
          </TextDownloadCSVHint>
        </div>
      )}
      <TableCustom
        loading={isLoading}
        columns={column({
          onToggleContact,
          onToggleHistoryCall,
          onToggleEditCallInfo,
          onTogglePackageModal,
          onToggleApplicantModal,
          onToggleNoteModal,
          onToggleQuotationModal,
          onToggleAdditionalNoteModal,
          options,
          salesCodeOption,
          pageStep,
        })}
        dataSource={dataSource}
        isShowStripe
        hasPagination={false}
        {...tableProps}
      />
    </div>
  );
}

export default TelesalesTable;
