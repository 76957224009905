import { useState } from 'react';
import { useExportExcel, useTableListQuery } from './use-table-query';
import { numberComma } from '../../../utils/number';

const useTableAction = () => {
  const [filters, setFilters] = useState({
    pageIndex: 0,
    pageSize: 10,
    name: '',
    caNumber: '',
    stageAuto: '',
    purchaseOccasion: '',
    keySuccess: '',
    stageManual: '',
    salesName: '',
    startDate: '',
    endDate: '',
    followUpStartDate: '',
    followUpEndDate: '',
    calledStatus: '',
    headCount: '',
    telNo: '',
    email: '',
  });

  const { isLoading, isFetching, data: tableData, error } = useTableListQuery(filters);

  const onSearch = (formValues) => {
    setFilters((prev) => ({
      ...prev,
      name: formValues?.name || '',
      caNumber: formValues?.caNumber || '',
      keySuccess: formValues?.keySuccess || '',
      stageManual: formValues?.stageManual || '',
      salesName: formValues?.salesName || '',
      startDate: formValues?.startDate || '',
      endDate: formValues?.endDate || '',
      stageAuto: formValues?.stageAuto || '',
      purchaseOccasion: formValues?.purchaseOccasion || '',
      followUpStartDate: formValues?.followUpStartDate || '',
      followUpEndDate: formValues?.followUpEndDate || '',
      calledStatus: formValues?.calledStatus || '',
      headCount: formValues?.headCount || '',
      telNo: formValues?.telNo || '',
      email: formValues?.email || '',
      pageIndex: 0,
      pageSize: 10,
    }));
  };

  const onPageChange = (currentPage, currentPageSize) => {
    setFilters((prev) => ({
      ...prev,
      pageIndex: currentPage - 1,
      pageSize: currentPageSize,
    }));
  };

  const { mutateAsync: onExportFile, isLoading: isExportLoading } = useExportExcel();

  const onExportExcel = async () => {
    const response = await onExportFile(tableData?.data);
    if (response?.success && response?.fileData) {
      const byteCharacters = atob(response?.fileData);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray]);

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = response?.filename;
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
  };

  const exportButtonProps = {
    onClick: onExportExcel,
    loading: isExportLoading,
  };

  const paginationProps = {
    defaultCurrent: 1,
    current: filters.pageIndex + 1,
    pageSize: filters.pageSize,
    total: tableData?.total,
    showTotal: (total) => `ทั้งหมด ${numberComma(total)}`,
    pageStep: filters.pageIndex * filters.pageSize + 1,
    onChange: onPageChange,
  };

  const searchInputProps = {
    onSearch,
    isLoading: isFetching || isLoading,
  };

  return {
    dataSource: tableData?.data,
    isLoading: isFetching || isLoading,
    error,
    onSearch,
    paginationProps,
    searchInputProps,
    exportButtonProps,
    filters,
  };
};

export default useTableAction;
