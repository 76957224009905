import { Divider, Select, Space } from 'antd';
import React from 'react';

function AssignForm({ options, selectValues, onSelect, resetForm }) {
  return (
    <>
      <div>
        <Space direction="vertical" style={{ width: '100%' }}>
          เลือกเซลที่ต้องการ assign
          <Select
            allowClear
            placeholder="เลือกเซล"
            showSearch
            options={options?.saleCode}
            style={{
              width: '50%',
            }}
            value={selectValues}
            onChange={onSelect}
            onClear={resetForm}
            filterOption={(inputValue, option) => {
              return option?.label.toLowerCase().includes(inputValue.toLowerCase());
            }}
            maxTagCount="responsive"
          />
        </Space>
      </div>
      <Divider />
    </>
  );
}

export default AssignForm;
